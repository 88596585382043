import React, { FC, useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Link, navigate } from 'gatsby';
import toast from 'react-hot-toast';
import { useUserContext } from 'common/UserProvider';

import { Trans } from 'react-i18next';
import { H3, P } from '@global/Texts';
import { MokensSignupButton } from '@global/Buttons';

import { HOME_URL, LOGIN_URL } from 'common/const';
import { validateEmail } from 'common/utils';
import { emailStatus } from 'common/server';

import GoogleIcon from 'images/google.svg';
import AppleIcon from 'images/apple.svg';

import '../styles.scss';

const SignUp: FC = () => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState([false, false]);
	const [userEmail, setUserEmail] = useState<string>('');
	const [otp, setOtp] = useState<string>('');
	const [error, setError] = useState<string[]>(['', '']);
	const { sendCode, validateCode } = useUserContext();
	const [emailListCheckbox, setEmailListCheckbox] = useState(false);

	const handleSendCode = async () => {
		setError(['', error[1]]);
		setIsLoading([true, isLoading[1]]);
		const userEmailStatus = await emailStatus({ email: userEmail });
		if (!isLoading[0] && userEmail && validateEmail(userEmail)) {
			if (userEmailStatus) {
				toast.error(t('passwordlessSignUp.newProfile.existingUserError'));
				setIsLoading([false, isLoading[1]]);
				return;
			}
			const response = await sendCode(userEmail);
			if (!response) {
				setError([t('passwordlessSignUp.newProfile.serverError'), error[1]]);
			}
		} else {
			setError([
				t('passwordlessSignUp.newProfile.invalidEmailDescription'),
				error[1],
			]);
		}
		toast.success(t('passwordlessSignUp.newProfile.codeSent'));
		setIsLoading([false, isLoading[1]]);
	};

	const handleValidateCode = async () => {
		setError([error[0], '']);
		setIsLoading([isLoading[0], true]);
		if (!isLoading[1] && otp) {
			const response = await validateCode(userEmail, otp, emailListCheckbox);
			if (!response) {
				setError([error[0], t('passwordlessSignUp.newProfile.serverError')]);
			} else navigate(HOME_URL);
		} else {
			setError([
				error[0],
				t('passwordlessSignUp.newProfile.invalidOtpDescription'),
			]);
		}
		setIsLoading([isLoading[0], false]);
	};

	return (
		<div className='new-profile'>
			<div className='new-profile-header'>
				<H3 className='py-2 text-center'>
					{t('passwordlessSignUp.newProfile.title')}
				</H3>
			</div>
			<div className='new-profile-content'>
				<P className='mb-4 text-orange'>
					{t('passwordlessSignUp.newProfile.description')}
				</P>
				<div className='mx-auto md:w-3/4'>
					<P className='mt-2 mb-2 mokens-input-passwordless-title'>
						{t('passwordlessSignUp.newProfile.email')}
					</P>
					<div className='flex flex-col md:flex-row gap-4'>
						<input
							className='mokens-input-passwordless'
							placeholder={t('passwordlessSignUp.newProfile.emailPlaceholder')}
							value={userEmail}
							onChange={(ev: any) => setUserEmail(ev.target.value)}
							type='email'
						/>
						<MokensSignupButton
							className='new-profile-button'
							text={t('passwordlessSignUp.newProfile.buttonSendcode')}
							variant='primary'
							color='yellow'
							onClick={handleSendCode}
							size='sm'
						></MokensSignupButton>
					</div>
					<div className='text-pink py-2'>{error[0]}</div>
					<P className='mt-2 mb-2 mokens-input-passwordless-title'>
						{t('passwordlessSignUp.newProfile.otp')}
					</P>
					<div className='flex flex-col md:flex-row gap-4'>
						<input
							className='mokens-input-passwordless flex-auto'
							placeholder={t('passwordlessSignUp.newProfile.otpPlaceholder')}
							value={otp}
							onChange={(ev: any) => setOtp(ev.target.value)}
							type='text'
						/>
						<MokensSignupButton
							className='new-profile-button'
							text={t('passwordlessSignUp.newProfile.button')}
							variant='secondary'
							onClick={handleValidateCode}
							enabled={
								otp != '' &&
								validateEmail(userEmail) &&
								validateEmail(userEmail).length > 0
							}
							size='sm'
						></MokensSignupButton>
					</div>
					<div className='text-pink py-2'>{error[1]}</div>
				</div>
				<div className='flex items-center gap-2 mb-4'>
					<input
						type='checkbox'
						className='w-6 h-6 rounded bg-blue-darkest accent-yellow-dark text-yellow-dark checked:bg-blue-darkest checked:hover:bg-blue-darkest checked:active:bg-blue-darkest checked:focus:bg-blue-darkest focus:bg-blue-darkest focus:outline-none focus:ring-yellow focus:active:ring-yellow'
						id='checkbox'
						checked={emailListCheckbox}
						onClick={() => {
							setEmailListCheckbox(!emailListCheckbox);
						}}
					/>
					<label className='mt-px cursor-pointer select-none text-left'>
						{t('passwordlessSignUp.newProfile.checkbox')}
					</label>
				</div>
				<span>
					<Trans>
					{t('passwordlessSignUp.newProfile.policy.part1')}{' '}
						<Link
							className='hover:text-blue underline'
							to={process.env.GATSBY_TERMS_URL}
						>
							{t('passwordlessSignUp.newProfile.policy.part2')}
						</Link>{' '}
						{t('passwordlessSignUp.newProfile.policy.part3')}{' '}
						<Link
							className='hover:text-blue underline'
							to={process.env.GATSBY_PRIVACY_URL}
						>
							{t('passwordlessSignUp.newProfile.policy.part4')}
						</Link>
						.
					</Trans>
				</span>
				<P className='mt-6 mb-0 text-white uppercase text-center'>
					{t('passwordlessSignUp.newProfile.social')}
				</P>
				<div className='flex justify-center gap-5 mt-4 mb-4'>
					<MokensSignupButton
						text=''
						variant='img'
						icon={GoogleIcon}
						onClick={() =>
							navigate(
								process.env.GATSBY_BASE_API_URL +
									`/social?provider=google-oauth2&response_type=code&flow=signup&mailing_list=${emailListCheckbox}`
							)
						}
					/>
					<MokensSignupButton
						text=''
						variant='img'
						icon={AppleIcon}
						onClick={() =>
							navigate(
								process.env.GATSBY_BASE_API_URL +
									`/social?provider=apple&response_type=code&flow=signup&mailing_list=${emailListCheckbox}`
							)
						}
					/>
				</div>
				<span className='flex h-auto justify-center items-end gap-1'>
					<Trans>
						{t('passwordlessSignUp.newProfile.existingAccount')}{' '}
						<Link className=' hover:text-blue underline' to={LOGIN_URL}>
							{' '}
							{t('passwordlessSignUp.newProfile.logIn')}
						</Link>
					</Trans>
				</span>
			</div>
		</div>
	);
};

export default SignUp;
