import React, { useEffect, useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';

import { PasswordlessSignUp } from 'common/context';

import { Toaster } from 'react-hot-toast';
import SEO from '@layout/SEO';
import Header from '@layout/Header';
import SignUp from '@sections/Passwordless/SignUp';

import metaImgPath from 'images/meta-img-pre-register.png';
import character02 from 'images/signup/Ghost_Legendary copy 1.png';
import character03 from 'images/signup/Bomb_Epic 1.png';
import brand from 'images/brand.png';

import './styles.scss';

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;

const Connect = ({ location }) => {
	const { t } = useTranslation();

	return (
		<>
			<SEO
				title={t('comingSoon.pageTitle')}
				description={t('comingSoon.pageDescription')}
				lang="en"
				imgPath={metaImgPath}
				imgAlt=""
				meta={[]}
			/>
			<main className="passwordless-connect">
				<Toaster />
				<a href="/" className="absolute top-6 left-6 z-50">
					<img
						width="112px"
						src={brand}
						alt="Mokens League Logo"
						className="logo"
					/>
				</a>
				<div className="blue-bg">
					<img className="character03" src={character03} />
					<div className="sign-up-flow relative">
						<SignUp />
					</div>
					<img className="character02" src={character02} />
				</div>
			</main>
		</>
	);
};

export default Connect;
